<template>
    <div class="p-2" v-if="shelter">

        <!-- header -->
        <div class="p-2" style="border-radius: 10px;border:1px solid #FFAD32">
            <img style="border-radius: 10px;" class="w-100" :src="shelter.shelter_picture" />

            <div class="text-white text-center my-2 px-2">
                <div class="d-flex align-items-center justify-content-center" style="gap:10px">
                    <h2 class="text-white font-weight-bolder mb-0">{{ shelter.shelter_name }}</h2>
                    <router-link :to="`/chats/${shelter.user_id}`" v-if="user.user_id != shelter.user_id">
                        <div class="text-white d-flex align-items-center"
                            style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                            <i class="bx bxs-message-dots" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                        </div>
                    </router-link>
                    <router-link :to="`/shelter-register`" v-else>
                        <div class="text-white d-flex align-items-center"
                            style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                            <i class="bx bxs-edit" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                        </div>
                    </router-link>
                </div>
                <p class="mt-1">
                    {{ shelter.shelter_details }}
                </p>

                <h3 class="font-weight-bold text-white">{{ shelter.shelter_location }}</h3>
                <h3 class="font-weight-bold text-white mb-0">{{ shelter.shelter_phone }}</h3>

            </div>

        </div>
        <!-- end header -->


        <h3 class="font-weight-bold text-white text-center mt-3">Now You Are My Pet Parent!</h3>
        <h3 class="font-weight-bold text-white text-center h4 mt-1">Please Contact My Shelter <br/> For Further Information</h3>

        <div class='d-flex'>
            <div class="mx-auto">
                <div class="d-flex mt-1">
                    <img :src="input_data.item.sha_picture ? input_data.item.sha_picture : default_img" class="rounded-circle m-auto"
                        style="height:100px;width:100px" />
                </div>

                <div class="text-center my-1">
                    <h3 class="text-white h4 mb-0">{{ input_data.item.sha_name }}</h3>
                    <h3 class="text-white h4 " style="margin: .25em 0 !important;">{{ input_data.item.sha_species }}</h3>
                    <h3 class="text-white h4 mb-0">{{ input_data.item.sha_age }} Months, {{ input_data.item.sha_gender }}</h3>
                    <p class="mt-1 text-white">
                        {{ input_data.item.sha_bio }}
                    </p>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center justify-content-end mt-1" style="gap:10px"> 
            <div>
                <router-link :to="`/profile`" class="text-white d-flex align-items-center"
                    style="padding:.5em 3em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 10px;">
                    <div class="h4 text-white mb-0 ml-auto">Back To Home</div>
                </router-link>
            </div>
        </div>


    </div>
</template>

<script>

import store from '@/store'

export default {
    computed: {
        user() {
            return store.state.auth.USER
        },
        shelter() {
            return store.state.shelter.SHELTER
        },
    },
    methods: {
    },
    mounted() {
        store.dispatch('shelter/GetShelter', this.id)
        this.input_data = this.$route.query
    },
    data() {
        return {
            input_data: {},
            id: this.$route.params.shelter_id,
            default_img: 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png'

        }
    }
}

</script>